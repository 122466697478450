<template>
  <div class="wrapper">
    <div>
      <h1 style="margin: 0 0;">time delta to:</h1>
      <input v-model="inputDate" type="date">
      <input v-model="inputTime" type="time">
      <div class="tpsummary">
        <span>{{ d }}</span>
        <span>d </span>
        <span>{{ h }}</span>
        <span>h </span>
        <span>{{ m }}</span>
        <span>m </span>
        <span>{{ s }}</span>
        <span>s</span>
      </div>
      <table>
        <tr>
          <td>{{ deltaSecs }}</td>
          <td>seconds</td>
        </tr>
        <tr>
          <td>{{ deltaMin }}</td>
          <td>minutes</td>
        </tr>
        <tr>
          <td>{{ deltaHours }}</td>
          <td>hours</td>
        </tr>
        <tr>
          <td>{{ deltaDays }}</td>
          <td>days</td>
        </tr>
      </table>
    </div>
  </div>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data: function () {
    return {
      fff: "wohoo",
      inputDate: "2023-08-09",
      inputTime: "16:04",
      deltaSecs: null,
      deltaMin: null,
      deltaHours: null,
      deltaDays: null,
      d:0,
      h:0,
      m:0,
      s:0
    }
  },
  computed: {
    dateTimeString: function () {
      return this.inputDate + "T" + this.inputTime + ":00";
    }
  },
  watch: {
    inputTime(t) {
      localStorage.time = t;
    },
    inputDate(d) {
      localStorage.date = d;
    }
  }
  ,
  methods: {
    timeElapsed: function () {
      var now = new Date();
      var start = new Date(this.dateTimeString);
      var deltaS = Math.abs((now.getTime() - start.getTime())/1000) ;
      this.deltaSecs = parseFloat(deltaS).toFixed(0);
      this.deltaMin = parseFloat(deltaS / 60).toFixed(1);
      this.deltaHours = parseFloat(deltaS / 60 / 60).toFixed(2);
      this.deltaDays = parseFloat(deltaS / 60 / 60 / 24).toFixed(2);

      var foo = deltaS;
      this.d = Math.floor(foo/60/60/24);
      foo -= this.d*60*60*24;
      this.h = Math.floor(foo/60/60);
      foo -= this.h*60*60;
      this.m = Math.floor(foo/60);
      foo -= this.m*60;
      this.s = parseFloat(foo).toFixed(0);
    }
  },
  components: {
    // HelloWorld
  },
  mounted() {
    if (localStorage.date) {
      try {
        this.inputDate = localStorage.date;
      } catch (error) {
        console.log(error);
      }
    }
    if (localStorage.time) {
      try {
        this.inputTime = localStorage.time;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created() {
    setInterval(() => {
      this.timeElapsed();
    }, 100)
  }
  ,
  unmounted() {
    clearInterval(this.timeElapsed); //no idea if this is working :)
  }

}
</script>

<style>
 :root {
  --blue: #1e90ff;
  --white: #ffffff;
  --highlightC: red;
}
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: lightblue;
}

body {
  background-color: #191919;
  font-size: 4vw;
  margin: 0;
  padding: 0;
}

input {
  font-size: 3vw;
}


table {
  margin: 0 auto;
  text-align: left;
}

td:nth-child(1) {
  text-align: right;
  color: var(--highlightC);
}
.tpsummary :nth-child(odd){
  color: var(--highlightC);
}


.wrapper {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
}

.wrapper>div {
  text-align: center;
  margin: 0 auto;
}
</style>
